import React, { Component } from 'react';
import styles from './App.module.scss';

import Header from './Header/Header';
import About from './About/About';
import Trasa from './Trasa/Trasa';
import Discography from './Discography/Discography';
import Navigation from './components/Navigation/Navigation';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {currentPage: 1};
    this._pageScroller = null;
  }
  state = {  }

  goToPage = (eventKey) => {
    this._pageScroller.goToPage(eventKey);
  };

  pageOnChange = (number) => {
    this.setState({currentPage: number});
  };

  render() { 

    return ( 
      <div className={styles.appWraper}>
          <Header />
          <About />
          <Trasa />
          <Discography />
        <Navigation />
      </div>
     );
  }
}
 
export default App;
