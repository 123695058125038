import React, { Component } from 'react';
import styles from './Trasa.module.scss';

import china from '../assets/images/china-poster.png';

class Trasa extends Component {
  state = {  }

  render() { 
    return ( 
      <div id="contact" className={styles.wrapper}>

        <div className={styles.left}>
          <h2 className={styles.title}>tras koncertowa</h2>
          <p className={styles.p}>11 października wylatujemy do Chin!!!!!</p>
          <p className={styles.p}>Będziemy promować naszą płytę nagraną wspólnie z Da Shu. Zostaliśmy zaproszeni na jeden z największych chińskich festiwali "Borderless Campus Rock Festiwal". Zagramy jeszcze w Pekinie, Shanghaju, Ningbo, Wuhan, Hefei. Odwiedzimy stacje radiowe i telewizyjne. Oczywiście będziemy podsyłać informacje i zdjęcia bezpośrednio z Chin. Lecimy w naszym mega składzie Zbyszek Man, Andrzej Man, Tomek Pacanowski i Grzegorz Daroń o dźwięk zadba Irek Kołodziej.</p>
        </div>

        <div className={styles.right}>
          <img className={styles.chinaMap} src={china} alt="Chiny" />
        </div>

      </div>
     );
  }
}
 
export default Trasa;