import React, { Component } from 'react';
import styles from './Header.module.scss';
import { TimelineLite, Power0, Back, Power4 } from 'gsap'; 

import roanLogo from '../assets/images/logo-roan-t.png';
import crowd from '../assets/images/background/crowd-gray.png';


class Header extends Component {
  constructor(props){
		super(props);
		this.tlHeader = new TimelineLite();
	}
  state = {  }

  componentDidMount() {
    this.tlHeader
    .from(this.divWrapper, 0.9, {opacity: 0, ease: Power0.easeNone})
    .from(this.logoImg, 2, {y: '-400px', scale: 4, opacity: 0, ease: Back.easeOut.config(1.7)}, 0.8)
    .from(this.crowdImg, 3, {bottom: '-100%', ease: Power4.easeOut}, '-=0.5')
    .from(this.logoImg, 15, {rotation: '-360deg', ease: Power0.easeNone, repeat:-1})
  }

  render() { 
    return ( 
      <div id="header" className={styles.wrapper} ref={div => this.divWrapper = div}>
        <img className={styles.logo} src={roanLogo} ref={img => this.logoImg = img} alt="roan logo" />
        <img className={styles.crowd} src={crowd} ref={img => this.crowdImg = img} alt="tłum" />
      </div>
     );
  }
}
 
export default Header;