import React, { Component } from 'react';
import styles from './Discography.module.scss';
import ReactPlayer from 'react-player'

import epulpit from '../assets/images/logo/ePulpit24-logo-white.png';
import bluserwer from '../assets/images/logo/bluSerwer-logo-white.png';
import blusoft from '../assets/images/logo/bluSoft-logo-white.png';

const url = 'https://www.youtube.com/watch?v=QIqYwZ9b944&feature=youtu.be&fbclid=IwAR1RqGw1m83FHboVDS5Pl6qjlcI5ffO1JQl4DHdnsku1jsRN9D0FXagLBns';

class Discography extends Component {
  state = {  }

  render() { 
    return ( 
      <div id="mapSection" className={styles.wrapper}>

        <div className={styles.left}>
          <h2 className={styles.title}>dyskografia</h2>
          <p className={styles.p}>Albumy studyjne</p>
          <h4 className={styles.albums}>Ogień (1995)</h4>
          <h4 className={styles.albums}>Cukier (1997)</h4>
          <h4 className={styles.albums}>Denis Rodman Club (1998)</h4>
          <h4 className={styles.albums}>Przez cały dzień przez cały czas (2001)</h4>
          <h4 className={styles.albums}>Soundcheck (2011)</h4>
          <h4 className={styles.albums}>Journey (2015)</h4>
          <p className={styles.p}>Albumy kompilacyjne</p>
          <h4 className={styles.albums}>ROAN 1995–2005 (2005)</h4>
        </div>

        <div className={styles.right}>
          <ReactPlayer 
            className={styles.video}
            url={url} 
            width='90%'
            height='90%'
            controls
          />
        </div>

        <div className={styles.partners}>
          <h3 className={styles.partnersTitle}>Partner zespołu</h3>
          <div className={styles.imgDiv}>
            <a className={styles.partnersLink} href="https://www.e-pulpit24.pl" target="_blank" rel="noopener noreferrer">
              <img className={styles.partnersImg} src={epulpit} alt="e-pulpit24" />
            </a>
            <a className={styles.partnersLink} href="https://www.blusoft.pl" target="_blank" rel="noopener noreferrer">
              <img className={styles.partnersImgSoft} src={blusoft} alt="blusoft"/>
            </a>
            <a className={styles.partnersLink} href="https://bluserwer.pl" target="_blank" rel="noopener noreferrer">
              <img className={styles.partnersImg} src={bluserwer} alt="bluserwer" />
            </a>
          </div>
        </div>

      </div>
     );
  }
}
 
export default Discography;