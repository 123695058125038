import React, { Component } from 'react';
import styles from './About.module.scss';

import band1 from '../assets/images/band1.jpg';
import band2 from '../assets/images/band2.jpg';
import band3 from '../assets/images/band3.jpg';


class About extends Component {
  state = { }


  render() { 
    return ( 
      <div id="about" className={styles.wrapper}>

        <div className={styles.left}>
          <img className={styles.bandImg} src={band1} alt="zespół" />
          <img className={styles.bandImg} src={band2} alt="zespół" />
          <img className={styles.bandImg} src={band3} alt="zespół" />
        </div>

        <div className={styles.right}>
          <h2 className={styles.title}>o zespole</h2>
          <p className={styles.p}>ROAN powstał w 1991 roku w Solcu Kujawskim.</p>
          <p className={styles.p}>Zespół zadebiutował w 1991 roku na Festiwalu w Jarocinie, którego został laureatem. Sukces ten, był początkiem ponad 20 letniej kariery grupy. W 1996 roku, ROAN nagrał jeden ze swoich największych przebojów pt.: „Ten zimowy czas”. Utwór nagrany z gościnnym udziałem Andrzeja Piasecznego, Fiolki Najdenowicz, DeSu oraz Nazara otworzył grupie drogę do popularności medialnej. Kolejne single ROANU cieszyły się ogromnymi sukcesami na listach przebojów polskich rozgłośni radiowych, w tym m.in. w Liście Przebojów „Trójki”, czy popularnym w latach 90-tych programie telewizyjnym „30 Ton - Lista, Lista” emitowanym przez TVP2.</p>
        </div>

      </div>
     );
  }
}
 
export default About;