import React, { Component } from 'react';
import styles from './Navigation.module.scss';
import { Link } from 'react-scroll';

class Navigation extends Component {
  state = {  }

  render() { 
    return ( 
      <div className={styles.wrapper}>
        <ul>

        <Link
          className={styles.navLink}
          activeClass={styles.navLinkActive}
          to="header"
          spy={true}
          smooth={true}
          duration={900}
          isDynamic={true}
        ></Link>

        <Link
          className={styles.navLink}
          activeClass={styles.navLinkActive}
          to="about"
          spy={true}
          smooth={true}
          duration={900}
          isDynamic={true}
        ></Link>

        <Link
          className={styles.navLink}
          activeClass={styles.navLinkActive}
          to="contact"
          spy={true}
          smooth={true}
          duration={900}
          isDynamic={true}
        ></Link>

        <Link
          className={styles.navLink}
          activeClass={styles.navLinkActive}
          to="mapSection"
          spy={true}
          smooth={true}
          duration={900}
          isDynamic={true}
        ></Link>

        </ul>
      </div>
     );
  }
}
 
export default Navigation;